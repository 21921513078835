import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "poposki_extended_contract_with_riga" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Fitness condition сoach from Northern Macedonia Sashko Poposki has come a long way with FC Riga from the first Latvian Title and both sides are not going to stop. This week FC Riga has extended the contract with coach from Northern Macedonia for another season. Sashko Poposki during his three seasons in coaching staff FC Riga won three Latvian Championships and one Latvian Cup. We wish Sashko the best of luck and important victories with FC Riga in new year!</p>
        </div>
      </div>
    )
  }
}